import { PrintQueueMaintenanceConstant } from "presentation/constant/PrintQueue/PrintQueueMaintenanceConstant";
import { usePrintQueueMaintenanceVM } from "presentation/hook/PrintQueue/usePrintQueueMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const PrintQueueEditPanelTitleBar: React.FC = () => {
    const [printQueueState] = usePrintQueueMaintenanceTracked();
    const printQueueVM = usePrintQueueMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = printQueueState;
    const {editingEntity, isAdd, isEditable, isRead} = masterState;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        printQueueVM.onReset();
    }, [printQueueVM]);

    const handleEdit = useCallback(()=>{
        printQueueVM.onEdit(currentEntity);
    },[currentEntity, printQueueVM])

    const handleSave = useCallback(async () => {
        if(currentEntity.enabledSeqLabel === 'Y' && currentEntity.sequentialPrintingLabel === null){
            messageBarVM.showWarining('Please select ['+PrintQueueMaintenanceConstant.SEQUENTIAL_PRINTING_ENABLED+'] and ['+PrintQueueMaintenanceConstant.SEQUENTIAL_PRINT_LABEL+'] together.');
            return;
        }
        if(currentEntity.monthPathActive === 'Y' && printQueueState.tableData.some(e=>e.monthPathActive==='Y' && e.id!==currentEntity.id)){
            messageBarVM.showWarining("Month Path Active can only have one record for 'Y'.");
            return;
        }
        if(currentEntity.defaultInvCn === 'Y' && printQueueState.tableData.some(e=>e.defaultInvCn==='Y' && e.id!==currentEntity.id)){
            messageBarVM.showWarining("Default (INV & CN) can only have one record for 'Y'.");
            return;
        }
        if(currentEntity.defaultSupportingDocument === 'Y' && printQueueState.tableData.some(e=>e.defaultSupportingDocument==='Y' && e.id!==currentEntity.id)){
            messageBarVM.showWarining("Default (Supporting Document) can only have one record for 'Y'.");
            return;
        }
        if(currentEntity.enabledSeqLabel === 'N'){
            currentEntity.sequentialPrintingLabel = null;
        }
        setIsLoading(true);
        try {
            printQueueVM.onSaveClicked();
            const res = await printQueueVM.onSave(currentEntity,isAdd);
            if(!!!res || !res.success){
                messageBarVM.showError(res?.data??'Save data failed.');
            }else {
                printQueueVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {
            
        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, printQueueState.tableData, printQueueVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: PrintQueueMaintenanceConstant.CATEGORY},
                { title: PrintQueueMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={printQueueVM.onClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={printQueueVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(PrintQueueEditPanelTitleBar);