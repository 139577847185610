import { comboBoxSequentialPrintOption } from "presentation/constant/PrintQueue/PrintQueueDropdownPropsOptions";
import { PrintQueueMaintenanceConstant } from "presentation/constant/PrintQueue/PrintQueueMaintenanceConstant";
import { usePrintQueueMaintenanceVM } from "presentation/hook/PrintQueue/usePrintQueueMaintenanceVM";
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import PrintQueueEditPanelTitleBar from "./PrintQueueEditPanelTitleBar";

export const PrintQueueMaintenanceFormPanel = () => {
    const [printQueueState] = usePrintQueueMaintenanceTracked();
    const printQueueVM = usePrintQueueMaintenanceVM();
    const {currentSelectedRow, masterState} = printQueueState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = PrintQueueMaintenanceConstant;
    

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const memoPrintQueue = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.printQueueName || ''}
                fieldValue={currentEntity?.printQueueName}
                fieldLabel={SCREEN_CONSTANT.PRINT_QUEUE_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'printQueueName'}
                isShowMissingError={true}
                maxLength={100}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    printQueueVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isRead, currentEntity?.printQueueName, SCREEN_CONSTANT.PRINT_QUEUE_NAME, isSaveClicked, printQueueVM])

        const memoDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!isAdd}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.printQueueDesc || ''}
                    fieldValue={currentEntity?.printQueueDesc}
                    fieldLabel={SCREEN_CONSTANT.PRINT_QUEUE_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'printQueueDesc'}
                    isShowMissingError={true}
                    maxLength={100}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        printQueueVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isAdd, currentEntity?.printQueueDesc, SCREEN_CONSTANT.PRINT_QUEUE_DESC, isSaveClicked, printQueueVM])

        const memoDefaultINCN = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"defaultInvCn"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.DEFAULT_INV_CN} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.defaultInvCn==='Y'}
                    disabled={isRead}
                    onChange={(e) => printQueueVM.onCheckboxChange(e.checked, "defaultInvCn")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.DEFAULT_INV_CN, currentEntity?.defaultInvCn, isRead, printQueueVM])
        const memoDefaultSupp = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"defaultSupportingDocument"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.DEFAULT_SUPPORTING_DOCUMENT} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.defaultSupportingDocument==='Y'}
                    disabled={isRead}
                    onChange={(e) => printQueueVM.onCheckboxChange(e.checked, "defaultSupportingDocument")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.DEFAULT_SUPPORTING_DOCUMENT, currentEntity?.defaultSupportingDocument, isRead, printQueueVM])

        const memoMonthPathActive = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"monthPathActive"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.MONTH_PATH_ACTIVE} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.monthPathActive==='Y'}
                    disabled={isRead}
                    onChange={(e) => printQueueVM.onCheckboxChange(e.checked, "monthPathActive")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.MONTH_PATH_ACTIVE, currentEntity?.monthPathActive, isRead, printQueueVM])

        const memoSeqLabelEnabled = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"enabledSeqLabel"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.SEQUENTIAL_PRINTING_ENABLED} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.enabledSeqLabel==='Y'}
                    disabled={isRead}
                    onChange={(e) => printQueueVM.onCheckboxChange(e.checked, "enabledSeqLabel")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.SEQUENTIAL_PRINTING_ENABLED, currentEntity?.enabledSeqLabel, isRead, printQueueVM])

        const memoSeqLabel = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.sequentialPrintingLabel || ''}
                    fieldValue={currentEntity?.sequentialPrintingLabel?.toString()}
                    fieldLabel={SCREEN_CONSTANT.SEQUENTIAL_PRINT_LABEL}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'sequentialPrintingLabel'}
                    isShowMissingError={true}
                    maxLength={10}
                    options={comboBoxSequentialPrintOption}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        printQueueVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.sequentialPrintingLabel, SCREEN_CONSTANT.SEQUENTIAL_PRINT_LABEL, isSaveClicked, printQueueVM])

            
        const memoPrintQueueTray = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.printQueueTray || ''}
                    fieldValue={currentEntity?.printQueueTray}
                    fieldLabel={SCREEN_CONSTANT.PRINT_QUEUE_TRAY}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'printQueueTray'}
                    isShowMissingError={true}
                    maxLength={20}
                    options={[]}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        printQueueVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.printQueueTray, SCREEN_CONSTANT.PRINT_QUEUE_TRAY, isSaveClicked, printQueueVM])

        const memoActiveInd = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd==='Y'}
                    disabled={isRead}
                    onChange={(e) => printQueueVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, printQueueVM])

        
    const memoEditPanelTitleBar = useMemo(() => {
        return <PrintQueueEditPanelTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{isAdd ? "NEW PRINT QUEUE" : currentEntity.printQueueName}</SidebarTitle>

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoPrintQueue}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDesc}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoDefaultINCN}{memoDefaultSupp}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoMonthPathActive}{memoSeqLabelEnabled}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSeqLabel}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoPrintQueueTray}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
