import { SelectionChangedEvent } from "ag-grid-community";
import { PrintQueueEntity } from "domain/entity/Common/PrintQueueEntity";
import { INITIAL_PRINT_QUEUE_COL_DEF } from "presentation/constant/PrintQueue/PrintQueueMaintenanceColumnDefinition";
import { PrintQueueMaintenanceConstant } from "presentation/constant/PrintQueue/PrintQueueMaintenanceConstant";
import { usePrintQueueMaintenanceVM } from "presentation/hook/PrintQueue/usePrintQueueMaintenanceVM";
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const PrintQueueTablePanel:React.FC = () => {
    const [printQueueState] = usePrintQueueMaintenanceTracked();
    const printQueueVM = usePrintQueueMaintenanceVM();
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    const {tableData} = printQueueState;
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    // const messageBarVM = useMessageBarVM();   

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        printQueueVM.updateSelectedRows(tableData,selectedRows);
    }, [printQueueVM, tableData])

    const handleAddClick = useCallback(() => {
        printQueueVM.onAdd();
    }, [printQueueVM])

    const handleRowDoubleClick = useCallback((entity: PrintQueueEntity) => {
          printQueueVM.onEdit(entity);
    }, [printQueueVM])

    const onDeletePrintQueue = useCallback((row: PrintQueueEntity) => {    
        printQueueVM?.onDelete(row);
    }, [printQueueVM]);

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='print-queue-table'
            headerLabel={PrintQueueMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_PRINT_QUEUE_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={true}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onDelete={(data: PrintQueueEntity) => onDeletePrintQueue(data)}
            onRowDoubleClick={(e: any, entity: PrintQueueEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, onDeletePrintQueue, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(PrintQueueTablePanel);
